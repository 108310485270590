// Import CSS for Vite. Path is relative to this JS file
import '../pcss/app.pcss'

//
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)
Alpine.plugin(focus)
window.Alpine = Alpine
Alpine.start()

// -------------------------------------------

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

// import styles bundle
import 'swiper/css/bundle'

// Carousel / Swiper - Carousel
const carouselSwiper = new Swiper('.carousel-swiper', {
	keyboard: true, // make it navigable with a keyboard
	slidesPerView: 1,
	spaceBetween: 40,
	loop: false,
	// centeredSlides: true,

	pagination: {
		el: '.carousel-pagination',
		clickable: true,
	},

	navigation: {
		nextEl: '.carousel-button-next',
		prevEl: '.carousel-button-prev',
	},
	breakpoints: {
		1024: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
	},
})

// Carousel / Swiper - Carousel
const quotesSwiper = new Swiper('.quotes-swiper', {
	keyboard: true, // make it navigable with a keyboard
	slidesPerView: 1,
	spaceBetween: 80,
	loop: true,

	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},

	autoplay: {
		delay: 5000,
	},

	pagination: {
		el: '.carousel-pagination',
		clickable: true,
	},

	navigation: {
		nextEl: '.carousel-button-next',
		prevEl: '.carousel-button-prev',
	},
})

// -------------------------------------------

// HMR
/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html & https://nystudio107.com/docs/vite/
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}
